import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
    {
        title: 'Jason Melo',
        designation: 'CTO @ CrossBorder',
        bio: 'Jason is a serial entrepreneur and technologist. Having founded companies and led both startups and Fortune 100 companies across Blockchain, Crypto, E-Commerce, HR Tech, Financial Services and others. Currently serving as Chief Technology Officer @ CrossBorder Solutions, a pre-IPO unicorn startup in the FinTech & Enterprise Tax space.',
        image: 'jfm-profile-pic.png',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/jasonmelo/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/jasonmelo'
            },
        ]
    },
    {
        title: 'Carl Gould',
        designation: 'Chief Discovery Strategist @ 7 Stage Advisors',
        bio: 'Carl has trained over 7,000 coaches in 35 countries, advised many of the fastest-growing companies, won Entrepreneur of the Year, co-authored Blueprint for Success with two of my idols, Ken Blanchard and Stephen R. Covey, spoke at Tony Robbins’ Unleash the Power Within seminars for 3 years, wrote 9 books, and ran 2 NYC marathons twice.',
        image: 'cg-profile-pic.png',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/carlgould/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/carlgould'
            },
        ]
    },
    {
        title: 'Mike Calhoun',
        designation: 'CEO @ Board of Advisors',
        bio: 'Founder & CEO of Board of Advisors, the world’s greatest community for founders, entrepreneurs, CEO’s, investors, marketers, e-commerce moguls, business developers and high level decision makers. Mike has over 27 years of consecutive entrepreneurial ownership experience and an endless rolodex to compliment.',
        image: 'mike_calhoun_bio.jpg',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/mikecalhoun/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/MikeCalhoun'
            },
        ]
    }
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/${value.image}`} alt="{`${value.title}`}"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                                <p className="bio">{value.bio}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;