import React, { Component , Fragment, useState } from "react";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderThreeDark";
import Team from "../elements/Team";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";

class Advisory extends Component{
    constructor () {
        super()
    }
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Shayra Antia" />

                {/* Start Header Area  */}
                <Header homeLink="/team" headertransparent="header--transparent" />
                {/* End Header Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 mt_sm--150 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 className="title">Shayra's Team</h2>
                                </div>
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h4 className="title">Advisory Board</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Advisory;