import React from 'react'
const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span>READY FOR EVEN MORE</span>
                            <h2>Join the community and conversation on Discord</h2>
                            <a className="rn-button-style--2" href="https://discord.gg/ntbDAY7GQU" target="_blank"><span>JOIN SHAYRA'S DISCORD</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;