import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderThreeDark";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiChevronUp , FiCheck } from "react-icons/fi";

 class ThankYou extends Component {
    
    render() {
        return (
            <>
                <Helmet pageTitle="Shayra Antia" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" />
                {/* End Header Area  */}
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">Thank You!</h1>
                                    <h3 className="sub-title">Welcome to Shayra's community, we're thrilled to have you on-board.</h3>
                                    <span>Now let's take the next step in your journey with us.</span>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">#discord channel access</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </>
        )
    }
}
export default ThankYou;
