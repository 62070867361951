import React, { Component , Fragment, useState } from "react";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderThreeDark";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import PortfolioList from "../elements/portfolio/PortfolioList";
import EbookModal from "../component/modal/ebook";

class FunnelLanding extends Component{
    constructor () {
        super()
    }
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Shayra Antia" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" />
                {/* End Header Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 mt_sm--150 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">Financial Educator &amp; Strategist</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start email capture modal  */}
                <EbookModal />
                {/* End email capture modal  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default FunnelLanding;