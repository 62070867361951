import React from 'react';
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import HeaderThree from "../component/header/HeaderThree";

const PortfolioLanding = () => {
    return (
        <div className="active-dark">
            <Helmet pageTitle="Shayra" />
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>

            {/* Start About Area */}
            <div id="about" className="fix col-lg-12">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <div className="about-inner inner">
                                        <div className="section-title center">
                                            <img src={`/assets/images/logo/logo-dark.png`} alt="SHAYRA.COM" width="400"/>
                                            {/* <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row--35 ptb--120 align-items-center">
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <div className="about-inner inner">
                                        <div className="section-title center">
                                        <a href="https://www.touchbrick.com"><img src={`/assets/images/logo/logo-blk.png`} width="400" alt="TOUCHBRICK"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default PortfolioLanding;
