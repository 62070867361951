import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import TagManager from 'react-gtm-module'

class PageHelmet extends Component{
    constructor(props) {
        super(props);
        const tagManagerArgs = {
            gtmId: 'G-SC9YD7GP2M'
        };
        
        TagManager.initialize(tagManagerArgs);
    }
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                    <meta name="description" content="Shayra Antia - Financial Educator and Strategist I want to empower and enable a million people across financial markets in the next decade. Advising crypto startups strategizing on their go-to-market strategy. Working with leaders across Finance and Technology, consulting with some of the largest brands in the world." />
                    <meta name="keywords" content="shayra, antia, shayra antia, finance, blockchain, crypto, crypto queen, cryptoqueen, tampa, NYC, defi, forex, mastermind, shayra, investing, crypto investing, leadership, crypto leadership" />
                    <meta name="robots" content="index, follow" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
