import React, { Component } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

class FreeAccessModal extends Component{
    constructor(props) {
        super(props);
        
        this.state = {
          show: false,
          ebFirstName: '',
          ebLastName: '',
          ebEmail: '',
        };
  
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.completeSignup = this.completeSignup.bind(this);
    }
    showModal = () => {
      this.setState({ show: true });
    };
      
    hideModal = () => {
      this.setState({ show: false });
    };

    completeSignup = () => {
      this.setState({ show: false });
    };
    render(){
        return(
            <Modal
                isOpen={this.state.show}
                onRequestClose={this.hideModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={true}
                shouldReturnFocusAfterClose={true}
            >
                <span role="button" className="modal-close" aria-label="close" onClick={this.hideModal}>x</span>
                <div className="row row--35 align-items-start">
                    <div className="col-lg-7 order-2 order-lg-1">
                        <div className="section-title service-style--3 text-left breadcrumb-inner">
                            <h2 className="title ">EM Free</h2>
                            <h4 className="title">Your first step in your journey to success in crypto investing, the future of the Blockchain &amp; Web3 technology</h4>
                            <p className="ebook-copy">enter your name and email below to gain immediate access to my community!</p>
                            <p>&nbsp;</p>
                        </div>
                        <div className="form-wrapper">

                            <form id="contact-form">

                                <label htmlFor="item00">
                                    <input
                                        type="text"
                                        name="ebFirstName"
                                        id="item00"
                                        placeholder="First Name *" 
                                        onChange={this.handleChange}
                                    />
                                </label>
                                &nbsp;
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="ebLastName"
                                        id="item01"
                                        placeholder="Last Name *" 
                                        onChange={this.handleChange}
                                    />
                                </label>
                                &nbsp;
                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="ebEmail"
                                        id="item02"
                                        placeholder="Your email *" 
                                        onChange={this.handleChange}
                                    />
                                </label>
                                &nbsp;
                                <button className="rn-button-style--2 btn-solid" type="button" value="submit" name="submit" id="mc-embedded-subscribe" onClick={this.handleSubmit}>Submit</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src="/assets/images/about/about-10.jpg" alt="EM Free" className="ebook-cover-101"/>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
    handleChange = event =>{
        this.setState({ [event.target.name]:event.target.value })
    }
    handleSubmit = e =>{
        e.preventDefault();

        const data = { email: this.state.ebEmail, firstName: this.state.ebFirstName, lastName: this.state.ebLastName }
      
        fetch('/api/freeaccess', {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' } 
        })
        .then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => this.completeSignup()); 
    }
}


export default FreeAccessModal;
