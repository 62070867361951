import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderThreeDark";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiChevronUp , FiCheck } from "react-icons/fi";
import ModalVideo from 'react-modal-video';

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--35',
        category: '',
        title: 'Your Favorite Investor',
        description: 'This video will show you how working with me will allow you to unlock your financial freedom!',
        buttonText: 'Watch the Video',
        buttonLink: '#'
    }
    
]

class WhatDoIDo extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Shayra Antia" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    <ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='601368235' onClose={() => this.setState({isOpen: false})} />
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`} onClick={this.openModal}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default WhatDoIDo;