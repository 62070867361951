import React, { Component } from "react";


const PortfolioList = [
    {
        image: '/assets/images/about/mike_calhoun_bio.jpg',
        category: 'Board of Advisors',
        title: 'Mike Calhoun',
        bioUrl: 'https://www.linkedin.com/in/mikecalhoun/'
    },
    {
        image: '/assets/images/about/kurtzendzian-bio.jpg',
        category: 'West Coast Customs X CarCoin',
        title: 'Kurt Zendzian',
        bioUrl: 'https://www.linkedin.com/in/kurt-zendzian-00a3b3159/'
    },
    {
        image: '/assets/images/about/jfm-profile-pic.png',
        category: 'CrossBorder Solutions',
        title: 'Jason Melo',
        bioUrl: 'https://www.linkedin.com/in/jasonmelo/'
    },
    {
        image: '/assets/images/about/about-10.jpg',
        category: 'TouchBrick',
        title: 'Shayra Antia',
        bioUrl: 'https://www.shayra.com/#about'
    }
]

class PortfolioOne extends Component{
    render(){
        return(
            <React.Fragment>
                {PortfolioList.map((value , index) => (
                    <div className="col-lg-3" key={index}>
                        <div className="portfolio">
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{ backgroundImage: `url(${value.image})` }}></div>
                                <div className={`bg-blr-image`} style={{ backgroundImage: `url(${value.image})` }}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={`${value.bioUrl}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.bioUrl}`} target="_blank">Speaker Bio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
            
        )
    }
}
export default PortfolioOne;