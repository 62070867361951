import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-6',
        category: 'Development',
        title: 'WHAT I DO', 
        url: '/whatido',
        to: '/whatido'
    },
    {
        image: 'image-9',
        category: 'Development',
        title: 'BLOCKCHAIN UNIVERSITY', 
        url: 'https://shayra.learnworlds.com/',
        to: '//shayra.learnworlds.com/'
    },
    {
        image: 'image-10',
        category: 'Development',
        title: 'YOUR FAVORITE INVESTOR APPAREL', 
        url: 'https://shayracom.square.site/s/shop',
        to: '//shayracom.square.site/s/shop'
    },
    {
        image: 'image-7',
        category: 'Development',
        title: 'FOLLOW ME ON INSTAGRAM', 
        url: 'https://www.instagram.com/shayraantia/',
        to: '//www.instagram.com/shayraantia/'
    },
    {
        image: 'image-5',
        category: 'Development',
        title: 'WORK WITH SHAYRA', 
        url: 'https://calendly.com/shayraantia',
        to: '//calendly.com/shayraantia'
    },
    {
        image: 'image-8',
        category: 'Development',
        title: 'EVENTS', 
        url: 'https://shayra.com/events/crypto-cars-092821',
        to: '//shayra.com/events/crypto-cars-092821'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4><a href={`${value.url}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.url}`}>Go!</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={`${value.to}`}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;