import React from 'react'
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiCheck } from "react-icons/fi";
import PortfolioList from "../elements/portfolio/PortfolioOne";
import Header from "../component/header/HeaderThreeDark";
import Footer from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";

const PricingTable = () => {
    return (
        <>
            <Helmet pageTitle="Crypto & Cars 9/28/21 - Shayra Antia" />

            {/* Start Header Area  */}
            <Header homeLink="/events/crypto-cars-092821" headertransparent="header--transparent" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Crypto & Cars'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                <div className="container">
                    {/* Start Portfolio Area */}
                    <div className="portfolio-area ptb--40 bg_color--5">
                        <div className="portfolio-sacousel-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center service-style--3 mb--30">
                                            <h2 className="title">Sept. 28th 2021 @ <a href="https://g.page/haikutampa?share" target="_blank">Haiku in Tampa, FL</a></h2>
                                            <p>This is one of The first series of Shayra Antia's national event series focused on the crypto-curious, automobile collectors and investors. This event will entail a private evening with Shayra Antia and her inner circle of crypto-whales, West Coast Customs, Blockchain Industry Experts & Car Coin NFT.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                                </div>
                                {/* <div className="row">
                                    <div className="col-lg-12">
                                        <div className="view-more-btn mt--60 text-center">
                                            <a className="rn-button-style--2 btn-solid" href="https://www.eventbrite.com/e/crypto-cars-tickets-170371351298"><span>Tickets</span></a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* End Portfolio Area */}

                    {/* Start Pricing Tbale Area  */}
                    <div className="rn-pricing-table-area ptb--40 bg_color--5">
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            {/* <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">On-Demand Event Recording</h4>
                                            <div className="pricing">
                                                <span className="price">FREE</span>
                                                <span className="subtitle">Available post-event</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Full Event Recording</li>
                                                <li><FiCheck /> Future Events Mailing List</li>
                                                <li><FiCheck /> Social Media Access</li>
                                                <li><FiCheck /> Community Slack Channel Waitlist</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/#contact">Register</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            {/* <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Crypto &amp; Cars Full Access</h4>
                                            <div className="pricing">
                                                <span className="price">$250</span>
                                                <span className="subtitle">USD</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Full Access to the In-Person Event</li>
                                                <li><FiCheck /> Pre &amp; Post Event Networking Opportunities</li>
                                                <li><FiCheck /> Future Events Mailing List</li>
                                                <li><FiCheck /> Social Media Access</li>
                                                <li><FiCheck /> Community Slack Channel Waitlist</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://www.eventbrite.com/e/crypto-cars-tickets-170371351298">Get Tickets</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            {/* <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Shayra's Inner Circle Bundle</h4>
                                            <div className="pricing">
                                                <span className="price"></span>
                                                <span className="subtitle"></span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            choose from:
                                            <ul className="list-style--1">
                                                <li><FiCheck /> EM Academy</li>
                                                <li><FiCheck /> EM Immersive Experience</li>
                                                <li><FiCheck /> EM Inner Circle </li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/#contact">I Want In!</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End PRicing Table Area  */}
                            
                        </div>
                    </div>
                    {/* End Pricing Tbale Area  */}
                </div>

                
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default PricingTable;