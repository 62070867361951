// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Demo from './home/PortfolioLanding';
import CorporateBusiness from './home/CorporateBusiness';
import FunnelLanding from './home/FunnelLanding';
import WhatDoIDo from './home/WhatDoIDo';
import error404 from "./elements/error404";
import ThankYou from "./elements/thankyou";
import PricingTable from "./blocks/PricingTable";
import Advisory from "./home/Advisory";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`/`} component={Demo}/>
                        
                        <Route exact path={`/events/crypto-cars-092821`} component={PricingTable}/>
                        <Route exact path={`/products`} component={CorporateBusiness}/>
                        <Route exact path={`/cryptotrading`} component={FunnelLanding}/>
                        <Route exact path={`/ig`} component={FunnelLanding}/>
                        <Route exact path={`/me`} component={FunnelLanding}/>
                        <Route exact path={`/whatido`} component={WhatDoIDo}/>
                        <Route exact path={`/team`} component={Advisory}/>

                        <Route exact path={`/thankyou`} component={ThankYou}/>
                        
                        <Route path={`/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();