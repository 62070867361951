import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderThreeDark";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import { FiChevronUp , FiCheck } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import FreeAccessModal from "../component/modal/freeAccess";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--35',
        category: '',
        title: 'EM Access.',
        description: 'Start your journey with me by gaining access to on-demand content, discounts on upcoming events, and an invite to my exclusive online community.',
        buttonText: 'Product Details',
        buttonLink: '#product-pricing'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--35',
        category: '',
        title: 'EM Academy.',
        description: 'Elevate your experience by enrolling in my Academy, focused on a deep learning experience across modern financial markets. Take it to the next level with live and in-person webinars, access to me directly and the full curriculum of the Blockchain University.',
        buttonText: 'Product Details',
        buttonLink: '#product-pricing'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--35',
        category: '',
        title: 'EM Immersive.',
        description: 'An exclusive 3 day event with me and the EM Masters to build your knowledge of Crypto/Blockchain, get certified and equip yourself and your business with the tools necessary to flourish in the new economy.',
        buttonText: 'Product Details',
        buttonLink: '#product-pricing'
    },
    
]

class CorporateBusiness extends Component{
    constructor () {
        super()
    }
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Shayra Antia" />

                {/* Start Header Area  */}
                <Header homeLink="/products" headertransparent="header--transparent" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--xx theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}

                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Shayra's Product Bundles</h2>
                                    <p>Select a Product Bundle to gain exclusive access to Shayra's Product Suite empowering real knowledge and execution across today's Financial Markets including Crypto, DeFi, NFT's &amp; more.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="product-pricing">
                            {/* Start Pricing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">EM Entry</h4>
                                            <div className="pricing">
                                                <span className="price">JOIN</span>
                                                <span className="subtitle">* entry level access</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> General Discord Channel</li>
                                                <li><FiCheck /> eBook</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#product-pricing" onClick={() => { this.freeaccess.showModal(); }}>Join Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Pricing Table Area  */}

                            {/* Start Pricing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">EM Access</h4>
                                            <div className="pricing">
                                                <span className="price">$49</span>
                                                <span className="subtitle">USD per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Private Discord Channel - a community of Insiders, Traders, Influencers, Technologists &amp; more</li>
                                                <li><FiCheck /> Access to event recordings</li>
                                                <li><FiCheck /> Discounted Event Tickets</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://payhip.com/b/khqfJ">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Pricing Table Area  */}

                            {/* Start Pricing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">EM Academy</h4>
                                            <div className="pricing">
                                                <span className="price">$499</span>
                                                <span className="subtitle">USD per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Everything from EM Access</li>
                                                <li><FiCheck /> Social media groups</li>
                                                <li><FiCheck /> LIVE group webinars &amp; Q&amp;A</li>
                                                <li><FiCheck /> Crypto Signals</li>
                                                <li><FiCheck /> Blockchain University</li>
                                                <li><FiCheck /> Access to Shayra</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://payhip.com/b/5JPlr">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Pricing Table Area  */}

                            {/* Start Pricing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">EM Immersive</h4>
                                            <div className="pricing">
                                                <span className="price">$4,999</span>
                                                <span className="subtitle">USD per Year</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Everything from EM Access &amp; EM Academy</li>
                                                <li><FiCheck /> 3 day event w/Shayra and the EM Masters to build your knowledge of Crypto/Blockchain</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="https://payhip.com/b/HgQab" target="_blank">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Pricing Table Area  */}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}

                {/* Start EM Free modal  */}
                <FreeAccessModal ref={instance => { this.freeaccess = instance; }} />
                {/* End EM Free modal  */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;